// extracted by mini-css-extract-plugin
export var blogsContainer = "index-module--blogsContainer--OUhSH";
export var collectionContainer = "index-module--collectionContainer--uwEY0";
export var contentTitle = "index-module--contentTitle--4pdyf";
export var ctaCustomButton = "index-module--ctaCustomButton--EmNrK";
export var gold = "index-module--gold--Lmire";
export var highlightContainer = "index-module--highlightContainer--vGB-t";
export var linkContainers = "index-module--linkContainers--6C4bh";
export var messageContainer = "index-module--messageContainer--rqJF7";
export var newArrivalsContainer = "index-module--newArrivalsContainer---3Its";
export var promotionContainer = "index-module--promotionContainer--ZpJcL";
export var root = "index-module--root--fQgxq";
export var socialContainer = "index-module--socialContainer--z2h01";
export var socialContentGrid = "index-module--socialContentGrid--OXwxv";
export var sustainableContainer = "index-module--sustainableContainer--zFOBs";
import React from 'react';
import * as styles from './ProductCollectionGrid.module.css';

import ProductCollection from '../ProductCollection';

const ProductCollectionGrid = (props) => {
  return (
    <div className={styles.root}>
      <ProductCollection
        image={'/collections/collection1.jpg'}
        title={'Masala powder'}
        text={'EXPLORE NOW'}
        link={'/shop?c=masala'}
      />
      <ProductCollection
        image={'/collections/collection2.jpg'}
        title={'Health mix'}
        text={'EXPLORE NOW'}
        link={'/shop?c=health'}
      />
      <ProductCollection
        image={'/collections/collection3.jpeg'}
        title={'Flour'}
        text={'EXPLORE NOW'}
        link={'/shop?c=flour'}
      />
      <ProductCollection
        image={'/collections/collection4.jpeg'}
        title={'Instant premix'}
        text={'EXPLORE NOW'}
        link={'/shop?c=instant'}
      />
    </div>
  );
};

export default ProductCollectionGrid;
